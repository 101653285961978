<template>
  <section class="control">
    <b-field label="Niveau">
      <b-slider
        :min="0"
        :max="sliderGrades.length - 1"
        :custom-formatter="positionToGrade"
        v-model="positions"
        ticks
        lazy
        @input="$emit('update:selected', selection)"
        class="px-2"
      >
        <b-slider-tick
          v-for="tick in sliderTicks"
          :key="tick"
          :value="gradeToPosition(tick)"
        >
          {{ tick }}
        </b-slider-tick>
      </b-slider>
    </b-field>
  </section>
</template>

<script>
import { allGrades, mergedGrades } from "@/constants/grades";

export default {
  name: "GradeSlider",
  props: {
    selected: Array,
  },
  computed: {
    /**
     * Takes the slider's min and max positions and uses those to calculate a list of selected grades. Merged slider
     * grades are taken into consideration as well.
     * @returns {string[]} All selected grades.
     */
    selection: function () {
      let selection = this.sliderGrades.slice(
        this.positions[0],
        this.positions[1] + 1
      );
      for (let i = 0; i < selection.length; i++) {
        let grade = selection[i];
        if (grade in mergedGrades) {
          selection.splice(i, 1);
          selection = selection.concat(mergedGrades[grade]);
        }
      }

      selection.sort();

      return selection;
    },
    sliderTicks: function () {
      return this.sliderGrades.filter((grade) => 0 > grade.indexOf("+"));
    },
  },
  data() {
    return {
      sliderGrades: this.mergeGrades(allGrades),
      positions: [],
    };
  },
  methods: {
    /**
     * Merges grades by checking if they exist in a list of grades to merge. If a grade should me merged, it's replaced
     * by its merge grade and the resulting list of grades is made unique to make sure each grade only shows up once.
     * @param {string[]} grades Grades to merge.
     * @returns {string[]} Merged grades.
     */
    mergeGrades: function (grades) {
      const merged = [];
      for (let i = 0; i < grades.length; i++) {
        let grade = grades[i];
        for (const [mergedGrade, mergedValues] of Object.entries(
          mergedGrades
        )) {
          if (-1 < mergedValues.indexOf(grade)) {
            grade = mergedGrade;
          }
        }

        merged.push(grade);
      }

      return [...new Set(merged)];
    },
    positionToGrade: function (position) {
      return this.sliderGrades[position];
    },
    gradeToPosition: function (grade) {
      return this.sliderGrades.indexOf(grade);
    },
  },
  created() {
    let selection = this.mergeGrades(this.selected);
    this.positions = [
      this.gradeToPosition(selection[0]),
      this.gradeToPosition(selection[selection.length - 1]),
    ];
  },
};
</script>