<template>
  <div>
    <div :class="'card card-' + route.color">
      <div class="card-content">
        <h4 v-if="route.new" class="ribbon">NEW</h4>
        <div v-if="route.completed" class="status">
          <b-icon icon="check" size="is-medium" type="is-success" />
        </div>
        <div class="media">
          <div class="media-left">
            <figure class="image is-48x48">
              <route-tag :color="route.color" :grade="route.grade" />
            </figure>
          </div>
          <div class="media-content">
            <p class="title is-5">
              {{ route.name }}
            </p>
            <p class="subtitle is-6">{{ route.wall }}</p>
          </div>
        </div>
      </div>
      <footer v-if="isExpanded" class="card-footer">
        <a
          class="card-footer-item"
          @click="climbedRoute(ClimbAttempt.ON_SIGHT)"
          v-show="!route.completed"
        >
          <b-icon icon="eye" size="is-medium" type="is-info" /> On-sight
        </a>
        <a
          class="card-footer-item"
          @click="climbedRoute(ClimbAttempt.FLASH)"
          v-show="!route.completed"
        >
          <b-icon icon="bolt" size="is-medium" type="is-warning" />Flash
        </a>
        <a
          class="card-footer-item"
          @click="climbedRoute(ClimbAttempt.REDPOINT)"
        >
          <b-icon icon="circle" size="is-medium" type="is-danger" />Redpoint
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import RouteTag from "@/components/RouteTag";
import ClimbAttempt from "@/constants/attempt";

export default {
  name: "RouteCard",
  components: {
    RouteTag,
  },
  mixins: [ClimbAttempt.Mixin],
  props: {
    route: Object,
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    climbedRoute: function (attempt) {
      this.$store
        .dispatch("routes/completeRoute", {
          routeId: this.route.id,
          attempt: attempt,
        })
        .then(() => this.$emit("close"))
        .catch(() => {
          this.$buefy.dialog.alert({
            title: "Wat doe je nou?",
            message:
              "Bjoeks en jij communiceren even niet zo lekker. De route is niet afgevinkt. Probeer het later nog eens.",
            confirmText: "Oké :/",
            type: "is-danger",
            hasIcon: true,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/variables";

.card {
  border-radius: 8px;
  overflow: hidden;
}

.card-footer {
  border-color: inherit;
}

.card-footer-item:not(:last-child) {
  border-color: inherit;
}

@each $name, $color in $route-colors {
  $base-color: map-get($color, "base");

  .card-#{$name} {
    @extend .card;
    box-shadow: 0px 0 0px 1px $base-color inset;
    background-color: mix($base-color, white, 5%);
    border-color: $base-color;
  }
}

// override the white card to give it a non-white border
.card-white {
  $gray-color: lightgray;
  box-shadow: 0px 0 0px 1px lightgray inset;
}

.status {
  position: absolute;
  top: 10px;
  right: 10px;
}

$ribbon-color: #ffdd57;
$ribbon-border: 1px solid adjust-color(black, $alpha: -0.9);
.ribbon {
  margin: 0;
  padding: 0;
  font-size: 14px;
  background: $ribbon-color;
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-30%) translateY(0%) rotate(-45deg);
  transform-origin: top right;
  border-top: $ribbon-border;
  border-bottom: $ribbon-border;
}
.ribbon:before,
.ribbon:after {
  content: "";
  position: absolute;
  top: -4px;
  margin: 3px -1px;
  width: 500%;
  height: 110%;
  background: $ribbon-color;
  border-top: $ribbon-border;
  border-bottom: $ribbon-border;
}
.ribbon:before {
  right: 100%;
}
.ribbon:after {
  left: 100%;
}
</style>