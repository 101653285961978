export const walls = [
	"Scorpion",
	"Styx",
	"Bout du Monde",
	"No Man's Land",
	"Emoro",
	"Zappa",
	"Rose des Sables",
	"Beda",
	"Fakir",
	"La Plage"
]
