import {
	faArrowUp,
	faBolt,
	faCheck,
	faCheckCircle,
	faCircle,
	faCog,
	faEnvelope,
	faExclamationCircle,
	faEye,
	faEyeSlash,
	faLock,
	faPlus,
	faRunning,
	faTimes,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";

library.add(
	faArrowUp,
	faBolt,
	faCheck,
	faCheckCircle,
	faCircle,
	faCog,
	faEnvelope,
	faExclamationCircle,
	faEye,
	faEyeSlash,
	faLock,
	faPlus,
	faRunning,
	faTimes,
	faUser,
)
Vue.component('vue-fontawesome', FontAwesomeIcon);