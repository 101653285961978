import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import Login from "@/views/Login";
import Routes from "@/views/Routes";
import Admin from "@/views/Admin";

Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		name: 'Routes',
		component: Routes,
		meta: {
			authenticated: true
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			guest: true
		}
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
		meta: {
			authenticated: true,
			admin: true
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

// Check unresolved routes, redirect them to /
router.beforeEach((to, from, next) => {
	if (0 === router.resolve(to).resolved.matched.length) {
		next('/')

		return
	}

	next()
})

router.beforeEach((to, from, next) => {
	// Make sure users are logged in
	if (to.matched.some(record => record.meta.authenticated) && !store.getters["authentication/isAuthenticated"]) {
		next(Login)

		return
	}

	// Authenticated users can't login
	if (to.matched.some(record => record.meta.guest) && store.getters["authentication/isAuthenticated"]) {
		next(Routes)

		return
	}

	next();
})

export default router
