import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import store from "@/store";
import router from "@/router";

axios.defaults.baseURL = process.env.VUE_APP_API_URL

// Add Authentication header to requests that require it
axios.interceptors.request.use(
	(request) => {
		if ('/auth/login' !== request.url) {
			request.headers.Authorization = 'Bearer ' + store.getters["authentication/getToken"].encoded
		}

		return request
	}
)

// Check expired tokens
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response && '/auth/login' !== error.response.config.url && 401 === error.response.status) {
			return store.dispatch("authentication/setToken", null)
				.then(() => {
					router.push(router.resolve({ name: 'Login' }).location)
						.then(() => Promise.reject(error))
				})

		}

		return Promise.reject(error)
	}
)

// Add loading state
axios.interceptors.request.use(
	(request) => {
		return store.dispatch('application/setLoading', { api: true })
			.then(() => request)
	}
)

// Remove loading state
axios.interceptors.response.use(
	(response) => {
		return store.dispatch('application/setLoading', { api: false })
			.then(() => response)
	},
	(error) => {
		return store.dispatch('application/setLoading', { api: false })
			.then(() => Promise.reject(error))
	}
)

// Show status message when something went wrong
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		let title = 'Dat is knap',
			message = 'De website is op een onbekende manier helemaal kapot gegaan. Probeer het straks nog eens zou ik zeggen.'

		// Resolve title & message from response if possible
		if (error.response) {
			if (400 <= error.response.status < 500) {
				title = 'Jij hebt iets niet goed gedaan'
			}

			switch (error.response.status) {
				case 401:
					message = 'Je e-mailadres of wachtwoord klopt niet. Weet je zeker dat je alles goed getypt hebt?'
					break

				case 404:
					message = 'Geen idee waar je naar op zoek bent maar dit bestaat helaas niet (meer).'
			}
		}

		// Return reject after setting status message
		return store.dispatch('application/setStatusMessage', { title: title, message: message, type: 'danger' })
			.then(() => Promise.reject(error))
	}
);

Vue.use(VueAxios, axios)
