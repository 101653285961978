<template>
  <div class="results">
    <b-loading v-model="isLoading" />
    <p v-show="!isLoading" class="is-size-6">{{ countText }}</p>
    <a
      @click="showRouteDetails(route.id)"
      v-for="route in filteredRoutes"
      :key="route.id"
    >
      <route-card class="my-2" :route="route" />
    </a>
    <b-modal v-model="isRouteModalActive" can-cancel trap-focus destroy-on-hide>
      <template #default="props">
        <route-card
          class="mx-2"
          :route="selectedRoute"
          @close="props.close"
          is-expanded
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import RouteCard from "@/components/RouteCard";

export default {
  name: "RouteCollection",
  components: {
    RouteCard,
  },
  props: {
    selectedWalls: Array,
    selectedGrades: Array,
    hideCompleted: Boolean,
    hideOld: Boolean,
  },
  data: function () {
    return {
      isMobile: false,
      isRouteModalActive: false,
      selectedRoute: null,
    };
  },
  methods: {
    showRouteDetails: function (routeId) {
      this.selectedRoute = this.filteredRoutes.find(
        (route) => route.id === routeId
      );
      this.isRouteModalActive = true;
    },
  },
  computed: {
    isLoading: function () {
      return this.$store.state.application.loading.api;
    },
    routes: function () {
      return this.$store.getters["routes/getRoutes"];
    },
    filteredRoutes: function () {
      return this.routes
        .filter(function (route) {
          return (
            this.selectedWalls.includes(route.wall) &&
            this.selectedGrades.includes(route.grade) &&
            (!this.hideCompleted || (this.hideCompleted && !route.completed)) &&
            (!this.hideOld || (this.hideOld && route.new))
          );
        }, this)
        .sort(function (x, y) {
          if (x.grade == y.grade) {
            return x.wall < y.wall ? -1 : x.wall > y.wall ? 1 : 0;
          } else {
            return x.grade < y.grade ? -1 : 1;
          }
        });
    },
    countText: function () {
      const count = this.filteredRoutes.length;
      if (count == 1) {
        return "1 resultaat";
      } else if (count > 1) {
        return count + " resultaten";
      } else {
        return "Geen routes gevonden. Je bent klaar, of je kunt niet zoeken.";
      }
    },
  },
  created() {
    this.$store.dispatch("routes/loadRoutes");
  },
};
</script>