import axios from "axios";
import Validator from "@/helper/validator";
import { allGrades } from "@/constants/grades";
import { walls } from "@/constants/walls";

const defaultFilters = {
	selectedGrades: allGrades,
	selectedWalls: walls,
	hideCompleted: true,
	hideOld: false,
}

export default {
	namespaced: true,
	state: {
		routes: [],
		filters: defaultFilters,
	},
	getters: {
		getFilters: state => state.filters,
		getRoutes: state => state.routes,
	},
	mutations: {
		COMMIT_FILTERS(state, filters) {
			localStorage.setItem('route_filters', JSON.stringify(filters))
			if (null === filters) {
				localStorage.removeItem('route_filters')
			}

			state.filters = filters || defaultFilters
		},
		COMMIT_ROUTES(state, routes) {
			state.routes = routes
		},
		COMPLETE_ROUTE(state, routeId) {
			state.routes.find((route) => route.id === routeId).completed = true;
		}
	},
	actions: {
		loadRoutes({ commit }) {
			axios.get('/routes/all')
				.then((response) => commit('COMMIT_ROUTES', response.data))
				.catch(() => {
					// Todo: Add a status message here?
					commit('COMMIT_ROUTES', [])
				})
		},
		completeRoute({ commit }, climb) {
			return axios.post(`/routes/${climb.routeId}/climbed`, {
				attempt: climb.attempt
			}).then(() => commit('COMPLETE_ROUTE', climb.routeId))
		},
		setFilters({ commit }, filters) {
			if (!Validator.isObject(filters, true)) {
				filters = defaultFilters
			}

			if (filters) {
				try {
					Validator.validateObjectProperties(filters, Object.keys(defaultFilters))
				} catch (Error) {
					filters = defaultFilters
				}
			}

			commit('COMMIT_FILTERS', filters)
		}
	},
}
