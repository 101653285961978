<template>
  <div id="app" class="container">
    <navbar v-if="user" />
    <b-loading is-full-page v-model="isLoading" />
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
export default {
  components: { Navbar },
  name: "App",
  computed: {
    isLoading: function () {
      return this.$store.state.application.loading.page;
    },
    user: function () {
      return this.$store.getters["authentication/getUser"];
    },
  },
};
</script>
