import axios from "axios";

export default {
	namespaced: true,
	state: {
		whitelist: [],
		admins: []
	},
	getters: {
		getWhitelist: state => state.whitelist,
		getAdmins: state => state.admins
	},
	mutations: {
		ADD_TO_WHITELIST(state, identity) {
			if (!state.whitelist.includes(identity) && !state.admins.includes(identity)) {
				state.whitelist.push(identity)
			}
		},
		REMOVE_FROM_WHITELIST(state, identity) {
			state.whitelist = state.whitelist.filter(id => id !== identity)
		},
		COMMIT_WHITELIST(state, whitelist) {
			state.whitelist = whitelist.identities
			state.admins = whitelist.admin_identities
		}
	},
	actions: {
		loadWhitelist({ commit }) {
			axios.get('/admin/whitelist')
				.then((response) => commit('COMMIT_WHITELIST', response.data))
				.catch(() => {
					commit('COMMIT_WHITELIST', [])
				})
		},
		addToWhitelist({ commit }, identity) {
			return axios.post('/admin/whitelist', { identity: identity })
				.then(() => {
					commit('ADD_TO_WHITELIST', identity)
				})
		},
		removeFromWhitelist({ commit }, identity) {
			return axios.delete(`/admin/whitelist/${identity}`)
				.then(() => {
					commit('REMOVE_FROM_WHITELIST', identity)
				})
		}
	}
}
