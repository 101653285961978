export const allGrades = [
	'3',
	'4',
	'5a',
	'5a+',
	'5b',
	'5b+',
	'5c',
	'5c+',
	'6a',
	'6a+',
	'6b',
	'6b+',
	'6c',
	'6c+',
	'7a',
	'7a+',
	'7b',
	'7b+',
	'7c',
	'7c+',
	'8a',
	'8a+',
	'8b',
	'8b+',
	'8c',
	'8c+',
	'9a',
]

export const mergedGrades = {
	'≤4': [
		'3', '4'
	],
	'≥7b': [
		'7b',
		'7b+',
		'7c',
		'7c+',
		'8a',
		'8a+',
		'8b',
		'8b+',
		'8c',
		'8c+',
		'9a',
	]
}
