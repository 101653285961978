import axios from "axios";
import JwtToken from "@/helper/jwt-token";
import router from "@/router";

export default {
	namespaced: true,
	state: {
		token: null
	},
	getters: {
		getToken: (state) => state.token,
		getUser: (state, getters) => {
			if (!getters.isAuthenticated) {
				return null
			}

			let payload = state.token.payload

			return {
				name: payload.name,
				admin: payload.admin,
			}
		},
		isAuthenticated: (state, getters) => !!getters.getToken,
	},
	mutations: {
		COMMIT_TOKEN(state, token) {
			// Remove old token's callback
			if (state.token) {
				state.token.onExpire = null
			}

			// Store new token
			state.token = token

			// Either store or remove the token in the local storage
			let encoded = token ? token.encoded : null
			localStorage.setItem('access_token', encoded)
			if (null === encoded) {
				localStorage.removeItem('access_token')
			}
		}
	},
	actions: {
		login({ dispatch }, user) {
			return axios.post('/auth/login', user)
				.then((response) => {
					dispatch('setToken', response.data.access_token)
				})
		},
		logout({ dispatch }) {
			return axios.get('/auth/logout')
				.then(() => {
					dispatch('setToken', null)
				})
		},
		setToken({ commit, dispatch }, token) {
			if (token) {
				token = JwtToken.decode(token)
				token.onExpire = () => {
					dispatch('setToken', null)
						.then(() => {
							router.push(router.resolve({ name: 'Login' }).location)
						})
				}
			}

			commit('COMMIT_TOKEN', token)
		}
	},
}
