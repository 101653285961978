import Validator from "@/helper/validator";

export default {
	namespaced: true,
	state: {
		loading: {
			page: false,
			api: false,
		},
		statusMessage: null,
	},
	getters: {},
	mutations: {
		COMMIT_LOADING(state, loading) {
			state.loading = Object.assign({}, state.loading, loading)
		},
		COMMIT_STATUS_MESSAGE(state, statusMessage) {
			state.statusMessage = statusMessage
		}
	},
	actions: {
		setLoading({ commit }, loading) {
			commit('COMMIT_LOADING', loading)
		},
		setStatusMessage({ commit }, statusMessage) {
			if (!Validator.isObject(statusMessage, true)) {
				throw Error('This method only excepts objects or null.')
			}

			if (statusMessage) {
				Validator.validateObjectProperties(statusMessage, ['title', 'message', 'type'])
				statusMessage.type = 'is-' + statusMessage.type
			}

			commit('COMMIT_STATUS_MESSAGE', statusMessage)
		},
	},
}
