<template>
  <div :class="'grade-tag tag-' + color">
    {{ grade }}
  </div>
</template>

<script>
export default {
  name: "RouteTag",
  props: {
    grade: String,
    color: String,
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/variables";

.grade-tag {
  width: 48px;
  height: 48px;

  border: 2.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 50%;
  line-height: 42px;

  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

@each $name, $color in $route-colors {
  .tag-#{$name} {
    background: map-get($color, "base");
    color: map-get($color, "text");
  }
}
</style>