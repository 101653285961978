<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-6-widescreen">
            <form @submit.prevent="submit" class="box">
              <status-message/>

              <b-field label="E-mailadres">
                <b-input
                    v-model="user.email"
                    placeholder="superklimmer@demuur.xxl"
                    type="email"
                    icon="envelope">
                </b-input>
              </b-field>

              <b-field label="Wachtwoord">
                <b-input
                    v-model="user.password"
                    placeholder="******"
                    type="password"
                    password-reveal
                    icon="lock">
                </b-input>
              </b-field>

              <b-field>
                <b-button native-type="submit" type="is-success">Inloggen</b-button>
              </b-field>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import StatusMessage from "@/components/StatusMessage";

const { mapActions } = createNamespacedHelpers('authentication');

export default {
  name: 'Login',
  components: { StatusMessage },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
    }
  },
  methods: {
    ...mapActions(['login']),
    submit() {
      this.$store.dispatch('application/setLoading', { page: true })
          .then(() => {
            this.login(this.user)
                .then(() => {
                  this.$store.dispatch('application/setStatusMessage', null)
                  this.$router.push(this.$router.resolve({ name: 'Routes' }).location)
                })
                .catch(() => {
                  // Error handling is performed in the interceptor
                })
                .finally(() => {
                  this.$store.dispatch('application/setLoading', { page: false })
                })
          })
    }
  }
}
</script>