<template>
  <b-message v-if="!!this.$store.state.application.statusMessage"
             :title="statusMessage.title"
             :type="statusMessage.type"
             has-icon
             @close="hide"
             aria-close-label="Sluiten">
    {{ statusMessage.message }}
  </b-message>
</template>

<script>
export default {
  name: 'StatusMessage',
  computed: {
    statusMessage: function () {
      return this.$store.state.application.statusMessage || { title: null, message: null, type: null }
    }
  },
  methods: {
    hide: function () {
      this.$store.dispatch('application/setStatusMessage', null)
    }
  }
}
</script>
