import Vue from 'vue';
import Vuex from 'vuex';
import application from "@/store/modules/application";
import authentication from "@/store/modules/authentication";
import routes from "@/store/modules/routes";
import admin from "@/store/modules/admin"

Vue.use(Vuex)
const store = new Vuex.Store({
	modules: {
		application,
		authentication,
		routes,
		admin
	},
})

// Initialize store
store.dispatch("authentication/setToken", localStorage.getItem('access_token'))

let filters = {}
try {
	filters = JSON.parse(localStorage.getItem('route_filters'))
} catch (SyntaxError) {
	// Pass
}
store.dispatch("routes/setFilters", filters)

export default store
