const ClimbAttempt = {
    REDPOINT: "REDPOINT",
    FLASH: "FLASH",
    ON_SIGHT: "ON_SIGHT",
    PROJECT: "PROJECT"
}

ClimbAttempt.Mixin = {
    created() {
        this.ClimbAttempt = ClimbAttempt
    }
}

export default ClimbAttempt