<template>
  <div class="columns mx-0">
    <div class="column is-one-quarter">
      <nav class="panel">
        <p class="panel-heading">Filters</p>

        <div class="panel-block">
          <checkbox-group
            label="Muur"
            :values="walls"
            :selected.sync="selectedWalls"
          />
        </div>

        <div class="panel-block">
          <grade-slider :selected.sync="selectedGrades" />
        </div>

        <div class="panel-block">
          <section class="control">
            <fieldset>
              <b-field label="Louter"/>
              <div class="columns is-mobile is-multiline mt-1 pb-3">
                <b-field class="column is-full py-0">
                  <b-checkbox v-model="hideCompleted">
                    Niet-afgeronde routes
                  </b-checkbox>
                </b-field>
                <b-field class="column is-full py-0">
                  <b-checkbox v-model="hideOld">
                    Nieuwe routes
                  </b-checkbox>
                </b-field>
              </div>
            </fieldset>
          </section>
        </div>
      </nav>
    </div>

    <div class="column">
      <route-collection
        :selectedWalls="selectedWalls"
        :selectedGrades="selectedGrades"
        :hideCompleted="hideCompleted"
        :hideOld="hideOld"
      />
    </div>
  </div>
</template>

<script>
import CheckboxGroup from "@/components/filters/CheckboxGroup";
import GradeSlider from "@/components/filters/GradeSlider";
import RouteCollection from "@/components/RouteCollection";
import { walls } from "@/constants/walls";

export default {
  name: "Routes",
  components: {
    CheckboxGroup,
    GradeSlider,
    RouteCollection,
  },
  computed: {
    user: function () {
      return this.$store.getters["authentication/getUser"];
    },
    filters: function () {
      return this.$store.getters["routes/getFilters"];
    },
    hideCompleted: {
      get() {
        return this.filters.hideCompleted;
      },
      set(value) {
        this.updateFilter({ hideCompleted: value });
      },
    },
    hideOld: {
      get() {
        return this.filters.hideOld;
      },
      set(value) {
        this.updateFilter({ hideOld: value });
      }
    },
    selectedGrades: {
      get() {
        return this.filters.selectedGrades;
      },
      set(value) {
        this.updateFilter({ selectedGrades: value });
      },
    },
    selectedWalls: {
      get() {
        return this.filters.selectedWalls;
      },
      set(value) {
        this.updateFilter({ selectedWalls: value });
      },
    },
  },
  data() {
    return {
      walls: walls,
    };
  },
  methods: {
    updateFilter(filter) {
      let filters = Object.assign({}, this.filters, filter);
      this.$store.dispatch("routes/setFilters", filters);
    },
  },
};
</script>
