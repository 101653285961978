import "@/plugins/axios"
import "@/plugins/buefy"
import "@/plugins/font-awesome"
import Vue from "vue";
import store from "@/store";
import router from "@/router";
import App from "@/App";

Vue.config.productionTip = 'development' === process.env.NODE_ENV

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
