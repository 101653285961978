<template>
  <section class="control">
    <fieldset>
      <b-field :label="label" />
      <div class="columns is-mobile is-multiline mt-1">
        <b-field class="column is-full py-0 pr-0">
          <b-checkbox
            v-model="allWallsSelected"
            :indeterminate="someWallsSelected"
            type="is-warning"
          >
            Alle muren
          </b-checkbox>
        </b-field>

        <b-field
          v-for="value in values"
          :key="value"
          class="column is-half py-0 pr-0"
        >
          <b-checkbox
            v-model="selection"
            :native-value="value"
            @input="$emit('update:selected', selection)"
          >
            {{ value }}
          </b-checkbox>
        </b-field>
      </div>
    </fieldset>
  </section>
</template>

<script>
export default {
  name: "Walls",
  props: {
    label: String,
    selected: Array,
    values: Array,
  },
  data() {
    return {
      selection: this.selected,
    };
  },
  computed: {
    someWallsSelected: function () {
      return (
        this.values.length != this.selection.length &&
        this.selection.length != 0
      );
    },
    allWallsSelected: {
      get() {
        return this.selection.length == this.values.length;
      },
      set(value) {
        if (value == true) {
          this.selection = this.values;
        } else {
          this.selection = [];
        }
        this.$emit("update:selected", this.selection);
      },
    },
  },
};
</script>
