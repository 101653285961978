<template>
  <div class="columns is-mobile has-background-primary mx-0 mt-0">
    <div class="column is-one-quarter">
      <router-link :to="{ name: 'Routes' }" class="is-flex is-vmiddle">
        <img class="logo-image" src="@/assets/img/logo.png" alt="">
        <span class="has-text-white is-family-delta">v2.0</span>
      </router-link>
    </div>

    <div class="column">
      <div class="buttons is-justify-content-flex-end">
        <b-button
            icon-left="user"
            type="is-primary"
            class="button-user mr-0 pr-2"
            inverted
            outlined>
          {{ user.name }}
        </b-button>

        <router-link v-if="user.admin" :to="{ name: 'Admin' }" class="button">
          <b-icon icon="cog" />
        </router-link>

        <b-button
          icon-right="running"
          title="Ik wil weg"
          type="is-danger"
          @click="logout"
        ></b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    user: function () {
      return this.$store.getters["authentication/getUser"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("application/setLoading", { page: true });
      this.$store.dispatch("authentication/logout").then(() =>
        this.$router
          .push(this.$router.resolve({ name: "Login" }).location)
          .then(() => {
            this.$store.dispatch("application/setStatusMessage", {
              title: "Uitgelogd",
              message: "Je bent uitgelogd",
              type: "success",
            });
            this.$store.dispatch("application/setLoading", { page: false });
          })
      );
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Delta";
  src: url("~@/assets/fonts/delta-book.otf");
}

.button-user {
  pointer-events: none;
  border-color: transparent !important;
}

.is-vmiddle {
  height: 3rem;
  margin-bottom: -.5rem;
  line-height: 2.5rem;
}

.logo-image {
  height: 2.5rem;
}

.is-family-delta {
  font-family: Delta, "sans-serif";
}

</style>