import base64url from "base64url";
import {Buffer} from 'buffer';

// Todo: required so stuff doesn't break (don't know why though)
window.Buffer = Buffer

/**
 * A JTW token {@link https://jwt.io/}.
 */
class JwtToken {
	/**
	 * Creates a new token.
	 * @param {{alg:string, typ:string}} header The header.
	 * @param {{}} payload The payload.
	 * @param {string} signature The signature.
	 */
	constructor(header, payload, signature) {
		this.header = header
		this.payload = payload
		this.signature = signature

		let encode = (component) => base64url(JSON.stringify(component))
		this.encoded = `${encode(header)}.${encode(payload)}.${signature}`

		this._onExpire = null
	}

	/**
	 * Decodes a token.
	 * @param {string} token The encoded token.
	 * @returns {JwtToken} The token.
	 */
	static decode(token) {
		let [header, payload, signature] = token.split('.'),
			decode = (component) => JSON.parse(base64url.decode(component))

		return new JwtToken(decode(header), decode(payload), signature)
	}

	/**
	 * Returns the callback that is executed when the token expires.
	 * @returns {null|function} The callback.
	 */
	get onExpire() {
		return this._onExpire
	}

	/**
	 * Sets the callback that is executed when the token expires. The callback receives an instance of this.
	 * @param {null|function} callback The callback.
	 */
	set onExpire(callback) {
		if (this._onExpire) {
			clearTimeout(this._onExpire)
		}

		this._onExpire = null
		if (callback) {
			this._onExpire = setTimeout(() => {
				callback(this)
			}, Math.max(0, this.payload.exp * 1000 - Date.now()))
		}
	}
}

export default JwtToken
