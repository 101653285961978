/**
 * Validator for types.
 */
class Validator {
	/**
	 * Checks if a value is an instance of a type.
	 * @param {*} value The value.
	 * @param {string} type The type.
	 * @param {boolean} nullable Whether null is allowed.
	 * @returns {boolean} If the value is an instance of type.
	 * @private
	 */
	static _isType(value, type, nullable = false) {
		let names = [Array.name, Boolean.name, 'Null', Number.name, Object.name, String.name],
			types = Object.fromEntries(names.map((name) => [name.toLowerCase(), `[object ${name}]`]))

		if (0 > Object.keys(types).indexOf(type.toLowerCase())) {
			throw Error(
				`Unsupported type "${type.toLowerCase()}". Supported types are "${Object.keys(types).join('", "')}".`
			)
		}

		let allowedTypes = types[type] + (nullable ? [types['null']] : [])

		return -1 < allowedTypes.indexOf(Object.prototype.toString.call(value))
	}

	/**
	 * Checks if a value is an object.
	 * @param {*} value The value.
	 * @param {boolean} nullable Whether null is allowed.
	 * @returns {boolean}
	 */
	static isObject(value, nullable = false) {
		return Validator._isType(value, 'object', nullable)
	}

	/**
	 * Validates an object's properties by making sure they exist.
	 * @param {{}} object The object.
	 * @param {[string]} properties The properties.
	 * @throws Error If one or more properties are missing.
	 */
	static validateObjectProperties(object, properties) {
		properties.forEach(property => {
			if (!(property in object)) {
				throw Error('Object does not contain a "' + property + '".')
			}
		})
	}
}

export default Validator
