<template>
  <div class="columns is-centered mx-0">
    <div class="column is-half-widescreen is-one-third-desktop">
      <nav class="panel">
        <p class="panel-heading">Whitelist</p>

        <div class="panel-block">
          <div class="control">
            <form @submit.prevent="addIdentity">
              <b-field>
                <b-input
                    v-model="identityInput"
                    placeholder="Voeg iemand toe..."
                    type="email"
                    icon="envelope"
                    required
                    expanded/>

                <p class="control">
                  <b-button type="is-primary" native-type="submit">
                    <b-icon icon="plus"/>
                  </b-button>
                </p>
              </b-field>
            </form>
          </div>
        </div>

        <div class="panel-block">
          <div class="control">
            <b-table
                :data="whitelist"
                :paginated="whitelist.length > perPage"
                :per-page="perPage"
                :mobile-cards="false"
            >
              <b-table-column field="admin" label="" v-slot="props" width="10%">
                <b-button
                    v-if="props.row.admin"
                    class="is-static"
                    size="is-small"
                >
                  <b-icon icon="lock"/>
                </b-button>
                <b-button
                    v-else
                    size="is-small"
                    type="is-danger"
                    @click="confirmRemoveIdentity(props.row)"
                >
                  <b-icon icon="times" custom-class="mt-2"/>
                </b-button>
              </b-table-column>
              <b-table-column
                  field="identity"
                  label="Email"
                  sortable
                  v-slot="props"
              >
                {{ props.row.identity }}
              </b-table-column>
            </b-table>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {
      perPage: 10,
      identityInput: "",
    };
  },
  methods: {
    addIdentity() {
      this.$store.dispatch("admin/addToWhitelist", this.identityInput);
      this.identityInput = "";
    },
    confirmRemoveIdentity(row) {
      this.$buefy.dialog.confirm({
        title: "Doei!",
        message: `Zeggen we <b>${row.identity}</b> vaarwel?`,
        confirmText: "Ja",
        cancelText: "Nee",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.removeIdentity(row),
      });
    },
    removeIdentity(row) {
      this.$store.dispatch("admin/removeFromWhitelist", row.identity);
    },
  },
  computed: {
    whitelist() {
      const identities = this.$store.getters["admin/getWhitelist"];
      const admins = this.$store.getters["admin/getAdmins"];

      const all = admins.concat(identities);
      return all.map((identity) => {
        return {
          identity: identity,
          admin: admins.includes(identity),
        };
      });
    },
  },
  created() {
    this.$store.dispatch("admin/loadWhitelist");
  },
};
</script>